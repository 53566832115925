<template>
  <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
    <h4 class="mb-10 font-weight-bold text-dark">{{ header }}</h4>
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
      </div>  
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'register-wizard-page',
  props: ['state', 'header'],
  components: {
    
  },
  mixins: [ toasts ],
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {
    
  }
};
</script>
