<template>
  <div>
    <div v-html="page.html"></div>

    <b-row>
      <b-col lg="6">
        <b-form-group id="personnr-group" label="Personnummer" label-for="personnr">
          <b-form-input
            id="personnr"
            name="personnr"
            v-model.trim="form.personnr"
            placeholder="ÅÅÅÅMMDD1234"
            :state="validatePersonnr('personnr')"
          />
          <b-form-invalid-feedback>Skriv in ett giltigt personnummer</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="student">
      <b-row>
        <b-col>
          <b-form-group class="col-6" id="firstname-group" label="Förnamn" label-for="firstname">
            <b-form-input
              name="firstname"
              placeholder="Förnamn"
              v-model="student.member.firstname"
            />
          </b-form-group>

          <b-form-group class="col-6" id="lastname-group" label="Efternamn" label-for="lastname">
            <b-form-input
              id="lastname"
              name="lastname"
              placeholder="Efternamn"
              v-model="student.member.lastname"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div v-for="education in student.educations">
        <p>{{ education.name }} {{ education.code }}</p>
      </div>

    </div>

    <div v-if="error">
      <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text">Ditt personnummer finns inte registrerat som student. Försök igen om några dagar eller kontakta kåren.</div>
      </div>
    </div>

    <div v-if="has_educations === false">
      <div class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text">Vi saknar uppgifter om dina kurser för nuvarande termin. Försök igen om några dagar eller kontakta kåren.</div>
      </div>
    </div>

  </div>
</template>

<script>
import formatPersonnr from '@/core/services/personnr';
import axios from 'axios';

export default {
  name: 'student-page',
  props: ['page', 'pageData', 'isRenew', 'requirements'],
  emits: ['wizardPageWasUpdated'],
  data() {
    return {
      form: {
        personnr: ''
      },
      student: null,
      error: null,
      has_educations: null,
      disabled: false,
      fmt_personnr: '',
      age: 0,
      skipPersonnr: false,
      hasDoneSparLookup: false
    };
  },

  watch: {
    'form.personnr'(newValue) {

      const valid = formatPersonnr(newValue);

      if (valid.valid) {
        this.checkPersonnrAsStudent(newValue);
      }
    }
  },
  methods: {
    checkPersonnrAsStudent(in_personnr) {
      axios
        .post(`/education/personnr`, { in_personnr: in_personnr })
        .then(res => {
          if (res.status === 200) {
            this.student = res.data;

            this.has_educations = this.student.educations && this.student.educations.length > 0;
          }
          else {
            this.error = true;
          }
        })
        .catch(err => {
          console.error(err);
          this.error = true;
        });
    },

    isNextButtonOK() {

      const personnr = this.form.personnr;

      let valid = formatPersonnr(personnr);

      return valid.valid;
    },
    getData() {
      let personnr = formatPersonnr(this.form.personnr);

      return {
        in_personnr: this.form.personnr,
        fmt_personnr: personnr.formatted,
        valid: personnr.valid
      }
    },

    formWasUpdated() {
      let personnr = formatPersonnr(this.form.personnr);

      this.$emit('wizardPageWasUpdated', this.page.type, { in_personnr: this.form.personnr, fmt_personnr: personnr.formatted, valid: personnr.valid });
    },

    isFieldValid(name) {},

    setForm(data) {
      this.formWasUpdated();
    },

    validatePersonnr() {
      if (!this.form.personnr) {
        return null;
      }

      const valid = formatPersonnr(this.form.personnr);
      return valid.valid;
    }
  }
};
</script>
