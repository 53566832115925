<template>
  <div>
    <div v-html="page.html"></div>

    <b-row>
      <b-form-group class="col-6" id="firstname-group" :label="$t('MEMBER.FIRSTNAME')" label-for="firstname">
        <b-form-input
          name="firstname"
          :placeholder="$t('MEMBER.FIRSTNAME')"
          v-model="$v.form.firstname.$model"
          :state="validateState('firstname')"
        ></b-form-input>
      </b-form-group>

      <b-form-group class="col-6" id="lastname-group" :label="$t('MEMBER.LASTNAME')" label-for="lastname">
        <b-form-input
          name="lastname"
          :placeholder="$t('MEMBER.LASTNAME')"
          v-model="$v.form.lastname.$model"
          :state="validateState('lastname')"
        ></b-form-input>
      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.personnr">
      <b-form-group
        :invalid-feedback="`Vårdnadshavare måste vara över ${page.settings.from_age} år`"
        class="col-6"
        id="personnr-group"
        :label="$t('MEMBER.PERSONNR')"
        label-for="personnr"
      >
        <b-form-input
          name="personnr"
          :placeholder="$t('MEMBER.PERSONNR')"
          v-model="$v.form.personnr.$model"
          :state="validateState('personnr')"
        ></b-form-input>
      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.phone">
      <b-form-group class="col-6" id="phone-group" :label="$t('MEMBER.PHONE')" label-for="phone">
        <b-form-input
          name="phone"
          :placeholder="$t('MEMBER.PHONE')"
          v-model="$v.form.phone.$model"
          :state="validateState('phone')"
        ></b-form-input>
      </b-form-group>
    </b-row>

    <b-row>
      <b-form-group class="col-6" id="email-group" :label="$t('MEMBER.EMAIL')" label-for="email">
        <b-form-input
          name="email"
          :placeholder="$t('MEMBER.EMAIL')"
          v-model.trim="$v.form.email.$model"
          :state="validateState('email')"
        ></b-form-input>
      </b-form-group>

      <b-form-group class="col-6" id="email2-group" :label="$t('MEMBER.REPEAT_EMAIL')" label-for="email2">
        <b-form-input
          name="email2"
          :placeholder="$t('MEMBER.REPEAT_EMAIL')"
          v-model.trim="$v.form.email2.$model"
          :state="validateState('email2')"
        ></b-form-input>
      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.address || page.settings.zipcode">
      <b-col lg="6" v-if="page.settings.address">
        <b-form-group id="address-group" :label="$t('MEMBER.ADDRESS')" label-for="address">
          <b-form-input
            name="address"
            :placeholder="$t('MEMBER.ADDRESS')"
            v-model="$v.form.address.$model"
            :state="validateState('address')"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6" v-if="page.settings.zipcode">
        <b-form-group
          style="max-width: 200px;"
          id="zipcode-group"
          :label="$t('MEMBER.ZIPCODE')"
          label-for="zipcode"
        >
          <b-form-input
            name="zipcode"
            placeholder="00000"
            v-model="$v.form.zipcode.$model"
            :state="validateState('zipcode')"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" v-if="page.settings.zipcode">
        <b-form-group id="post-group" :label="$t('MEMBER.POST')" label-for="post">
          <b-form-input
            name="post"
            :placeholder="$t('MEMBER.POST')"
            v-model="$v.form.post.$model"
            :state="validateState('post')"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="page.settings.region || page.settings.commune">
      <b-col lg="6">
        <b-form-group id="input-group-region_code" :label="$t('MEMBER.REGION')" label-for="input-region_code">
          <b-form-select
            :options="regionOptions"
            name="region_code"
            v-model="$v.form.region_code.$model"
            :state="validateState('region_code')"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col v-if="page.settings.commune" lg="6">
        <b-form-group id="input-group-commune_code" :label="$t('MEMBER.COMMUNE')" label-for="input-commune_code">
          <b-form-select
            :options="communeOptions"
            name="commune_code"
            v-model="$v.form.commune_code.$model"
            :state="validateState('commune_code')"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import axios from 'axios';
import { isNameValid, isAddressValid, isPhoneValid } from '@/core/services/validators';
import formatPersonnr, { validate_personnr, over_age } from '@/core/services/personnr';
import { required, numeric, email, sameAs, maxLength, minLength } from 'vuelidate/lib/validators';
import { validate_email } from '@/core/services/email';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'parents-page',
  mixins: [ toasts ],
  props: ['page', 'page_data', 'age', 'ageMaxParent', 'regions', 'requirements', 'parent'],
  emits: ['update:page_data', 'wizardPageWasUpdated'],
  components: {},
  computed: {
    regionOptions: function() {
      const opts = [];

      for (var k in this.regions) {
        opts.push({ value: k, text: this.regions[k].region });
      }

      opts.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });

      return opts;
    }
  },
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        // birthdate: '',
        email: '',
        email2: '',
        address: '',
        zipcode: '',
        post: '',
        region_code: '',
        commune_code: '',
        phone: '',
        personnr: '',
        dob: ''
      },
      personnr: '',
      fmt_personnr: '',
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      valid: false,
      skipPersonnr: false,
      communeOptions: [],
      region_code: null,
      commune_code: null,
      isBirthdate: false,
      disabled_birthdate: false,
      states: {}
    };
  },
  validations() {
    return {
      form: {
        firstname: {
          required,
          isNameValid
        },
        lastname: {
          required,
          isNameValid
        },
        // birthdate: {
        //   required: !this.page.settings.birthdate || required,
        //   numeric,
        //   minLength: !this.page.settings.birthdate ? true : maxLength(8),
        //   maxLength: maxLength(8),
        // },
        dob: { required: false },
        co: { required: false },
        email: {
          required,
          email
        },
        email2: {
          sameAsEmail: sameAs('email')
        },
        address: {
          required: !this.page.settings.address || required,
          isAddressValid
        },
        zipcode: {
          required: !this.page.settings.zipcode || required,
          numeric,
          minLength: !this.page.settings.zipcode ? true : minLength(5),
          maxLength: maxLength(5)
        },
        post: {
          required: !this.page.settings.zipcode || required,
          isNameValid
        },
        phone: {
          required: !this.page.settings.phone || required,
          isPhoneValid,
          minLength: !this.page.settings.phone ? true : minLength(8),
          maxLength(value) {
            if (value && value.startsWith('+')) return maxLength(15);
            return maxLength(12);
          }
        },
        region_code: {
          required: !this.page.settings.region || required
        },
        commune_code: {
          required: !this.page.settings.commune || required
        },
        personnr: {
          required: !this.page.settings.personnr || required,
          validate_personnr,
          overAge: over_age(this.page.settings.from_age)
        }
      }
    };
  },
  mounted() {
    if (this.page_data) {
      this.local_page_data = { ...this.page_data };
    }
    if (this.parentRecord) {
      this.form = this.parentRecord;
    }

    console.log('mounted parent', this.parentRecord);

    this.$emit('wizardPageWasUpdated', this.page.type, this.form, this.isNextButtonOK());
  },
  watch: {
    parentRecord(newValue) {
      this.form = newValue;

      console.log('watch parent', newValue);
    },
    page_data: {
      deep: true,
      handler(val) {
        this.local_page_data = { ...val };
      }
    },
    form: {
      handler() {
        this.local_page_data[this.page.id] = {
          ...this.local_page_data[this.page.id],
          form: { parents: this.form },
          validation: this.$v
        };
        this.$emit('update:page_data', this.local_page_data);
        this.formWasUpdated();
      },
      deep: true
    },
    'form.personnr': function(newVal) {
      if (!this.$v.form.personnr.$invalid) {
        this.$v.form.personnr.$model = formatPersonnr(newVal).formatted;
      }
    },
    'form.region_code': function(newVal) {
      this.loadCommunes(newVal);
    },
    'form.zipcode': function(newValue) {
      if (newValue !== null && newValue !== undefined && (newValue + '').length === 5) {
        axios
          .get(`https://zip.memlist.se/api/v1/zip/${newValue}`)
          .then(res => {
            if (res.status === 200) {
              this.$v.form.post.$model = res.data.post;
              this.form.region_code = res.data.region.code;
              this.form.commune_code = res.data.commune.code;
            }
          })
          .catch(err => {});
      }
    }
  },
  methods: {
    formWasUpdated() {

      const formatted = formatPersonnr(this.form.personnr);

      if (formatted.success) {
        this.form.dob = formatted.year + '' + formatted.month + '' + formatted.day;
      }

      this.$emit('wizardPageWasUpdated', this.page.type, this.form, this.isNextButtonOK());
    },
    reqField(name) {
      switch (name) {
        case 'firstname': return this.requirements.MEMBER_REQ_FULLNAME;
        case 'lastname': return this.requirements.MEMBER_REQ_FULLNAME;
        case 'email': return this.requirements.MEMBER_REQ_EMAIL;
        case 'email2': return this.requirements.MEMBER_REQ_EMAIL;
        case 'phone': return this.requirements.MEMBER_REQ_PHONE;
        case 'dob': return this.requirements.MEMBER_REQ_PERSONNR;
        case 'address': return this.requirements.MEMBER_REQ_ADDRESS;
        case 'personnr': return this.requirements.MEMBER_REQ_PERSONNR;

      }

      return false;
    },
    getData() {
      return {
        email: this.$v.form.email.$model,
        firstname: this.$v.form.firstname.$model,
        lastname: this.$v.form.lastname.$model,
        dob: this.$v.form.dob.$model,
        personnr: this.$v.form.personnr.$model,
        address: this.$v.form.address.$model,
        zipcode: this.$v.form.zipcode.$model,
        co: this.$v.form.co.$model,
        region_code: this.$v.form.region_code.$model,
        commune_code: this.$v.form.commune_code.$model,
        post: this.$v.form.post.$model,
        phone: this.$v.form.phone.$model
      }
    },
    isNextButtonOK() {


      if (
            this.requirements.MEMBER_REQ_EMAILPHONE &&
            (!this.isFieldValid('phone') && (!this.isFieldValid('email') || this.$v.form.email.$model !== this.$v.form.email2.$model) )
          ) {

        return false;
      }

      if (this.page.settings.firstname && this.page.settings.lastname && this.requirements.MEMBER_REQ_FULLNAME && !this.isFieldValid('firstname') && !this.isFieldValid('lastname')) {
        return false;
      }

      if (this.page.settings.phone && this.requirements.MEMBER_REQ_PHONE && !this.isFieldValid('phone')) {

        return false;
      }

      if (this.page.settings.birthdate && (this.requirements.MEMBER_REQ_PERSONNR || this.requirements.MEMBER_REQ_AGE) && !this.isFieldValid('dob')) {

        return false;
      }

      if (this.page.settings.address && this.requirements.MEMBER_REQ_ADDRESS && !this.isFieldValid('address')) {

        return false;
      }

      if (this.page.settings.email && this.requirements.MEMBER_REQ_EMAIL && (!this.isFieldValid('email') || this.$v.form.email.$model !== this.$v.form.email2.$model)) {

        return false;
      }

      return true;
    },
    isFieldValid(name) {
      var value = this.$v.form[name].$model+'';

      if (name === 'landline') {
        return true;
      }

      if (name === 'firstname') {
        return !!value && validate_name(value);
      }
      if (name === 'lastname') {
        return !!value && validate_name(value);
      }

      if (name === 'phone') {

        if (value && (value.startsWith('+') || value.startsWith('00'))) {
          return value.length < 16 ? true : false;
        }

        return value.length > 6 && value.length < 13 ? true : false;
      }

      if (name === 'dob') {
        if (value && value.length === 8) {
          var ret = validate_pers_number(value);

          return ret.success;
        }

        return false;
      }

      if (name === 'email') {
        return (!!value && validate_email(value));
      }

      if (name === 'email2') {
        return (!!value && validate_email(value)) && this.$v.form.email.$model === this.$v.form.email2.$model;
      }

      if (name === 'address') {
        if (value && value.length > 2) {
          return true;
        }

        return false;
      }

      if (name === 'zipcode') {
        value = (value+'').replace(/ /g, '');
        if (value && (value.match(/[a-zA-Z]/i) ? true : try_parse_int(value, 0) > 0) && value.length >= 5) {
          return true;
        }

        return false;
      }

      return true;

    },
    setForm(parent) {

      this.$v.form.personnr.$model = parent?.personnr || '';
      this.$v.form.firstname.$model = parent?.firstname || '';
      this.$v.form.lastname.$model = parent?.lastname || '';
      this.$v.form.email.$model = parent?.email || '';
      this.$v.form.email2.$model = parent?.email || '';
      this.$v.form.phone.$model = parent?.phone || '';
      this.$v.form.address.$model = parent?.address || '';
      this.$v.form.zipcode.$model = parent?.zipcode || '';
      this.$v.form.post.$model = parent?.post || '';
      this.$v.form.region_code.$model = parent?.region_code || '';
      this.$v.form.commune_code.$model = parent?.commune_code || '';

      this.formWasUpdated();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    loadCommunes(selectedRegion) {
      this.communeOptions = [];

      var communes = this.regions[selectedRegion].communes;
      this.communes = communes;

      for (var k in communes) {
        this.communeOptions.push({ value: k, text: communes[k] });
      }

      this.communeOptions.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    }
  }
};
</script>
