<template>
  <div>
    <b-card>
      <h5 class="mb-4">{{ group.name }}</h5>
      <p v-if="group.descr && group.descr !== ''">{{ group.descr }}</p>
      <p v-if="group.is_mandatory" class="mt-2"><strong>{{$t('COMMON.MANDATORY')}}</strong></p>

      <div v-if="group.is_multi">
        <PropertyPageGroupCheckboxProperties
          :group="group"
          @attributes_selected="attributes_selected"
        />
      </div>

      <div v-if="!group.is_multi && !group.is_dropdown">
        <PropertyPageGroupRadioProperties
          :group="group"
          @attributes_selected="attributes_selected"
        />
      </div>

      <div v-if="!group.is_multi && group.is_dropdown">
        <PropertyPageGroupDropdownProperties
          :group="group"
          @attributes_selected="attributes_selected"
        />
      </div>
    </b-card>
  </div>
</template>
<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import PropertyPageGroupRadioProperties from '@/view/pages/members/register/registerwizard/PropertyPageGroupRadioProperties.vue';
import PropertyPageGroupDropdownProperties from '@/view/pages/members/register/registerwizard/PropertyPageGroupDropdownProperties.vue';
import PropertyPageGroupCheckboxProperties from '@/view/pages/members/register/registerwizard/PropertyPageGroupCheckboxProperties.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'PropertyPageGroup',
  mixins: [ toasts ],
  props: ['group'],
  emits: ['attributes_selected'],
  components: {
    PropertyPageGroupRadioProperties,
    PropertyPageGroupCheckboxProperties,
    PropertyPageGroupDropdownProperties
  },
  data() {
    return {

      isValid: false,

    };
  },
  mounted() {

  },
  watch: {

  },
  methods: {

    /** selected_attributes = [ { prop_id, text } ] */
    attributes_selected(group, selected_attributes) {


      if (this.group.is_mandatory) {
        this.isValid = selected_attributes.length > 0;
      } else {
        this.isValid = true;
      }

      this.$emit('attributes_selected', this.group, selected_attributes, this.isValid);
    },

    /*selected(group, selected) {
      for (var i = 0; i < this.group.properties.length; ++i) {
        this.group.properties[i].selected = false;
      }

      // set the property
      for (var i = 0; i < this.group.properties.length; ++i) {
        if (this.isMulti) {
          for (var j = 0; j < selected.length; ++j) {
            if (this.group.properties[i].prop_id === selected[j]) {
              this.group.properties[i].selected = true;
            }
          }
        } else {
          if (this.group.properties[i].prop_id === selected) {
            this.group.properties[i].selected = true;
          }
        }
      }

      if (this.isMulti) {
        if (this.isMandatory) {
          this.isValid = this.group.properties.some(prop => prop.selected);
        } else {
          this.isValid = true;
        }
      } else {
        this.isValid = true;
      }

      this.$emit('selected', group, selected, this.isValid);
    }*/
  }
};
</script>
