<template>
  <div>
    <div v-html="page.html"></div>

    <div v-if="loading">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>

    <div v-else>

      <div v-if="error_occured !== null">
        {{ error_occured }}
        <b-alert show variant="danger">
          {{ $t('PAGES.REGISTRATION.ERROR_OCCURED') }}
        </b-alert>
      </div>

      <div v-else>

        <!-- If admin has selected to show the top company -->
        <!-- it will be automatically selected -->
        <b-row class="mt-8" v-if="root_company.show">
          <b-col>

            <div class="boxed-select">
              <p class="boxed-header mb-4">{{ company_select_labels[0] }}</p>
              <div class="checkbox-list">
                <div class="d-flex align-items-center mb-6">
                  <label class="radio radio-outline radio-success mr-5">
                    <input
                      type="radio"
                      v-model="root_company_visual_select"
                      name="root_company_visual_select"
                      :value="root_company.company_id"
                      @change="level_was_selected(0)"
                    />
                    <span class="mr-2"></span>{{ root_company.name }}
                  </label>
                </div>
              </div>
            </div>

          </b-col>
        </b-row>

        <!-- next level 1 -->
        <b-row class="mt-8 level1-selection" v-if="show_level_1">
          <b-col>

            <div class="boxed-select">
              <p class="boxed-header mb-4">{{ company_select_labels[1] }}</p>

              <div class="checkbox-list">
                <template v-for="(level1, i) of companies_level_1">
                  <div :key="i" v-if="level1.show">
                    <div class="d-flex align-items-center mb-6">
                      <label class="radio radio-outline radio-success mr-5">
                        <input
                          type="radio"
                          v-model="company_level_1_select"
                          name="company_level_1_select"
                          :value="level1.company_id"
                          @change="level_was_selected(1)"
                        />
                        <span class="mr-2"></span>{{ level1.name }}
                      </label>
                    </div>
                  </div>
                </template>
              </div>
            </div>

          </b-col>
        </b-row>

        <!-- next level 2 -->
        <b-row class="mt-8 level2-selection" v-if="companies_level_2.length > 0">
          <b-col>
            <div class="boxed-select">
              <p class="boxed-header mb-4">{{ company_select_labels[2] }}</p>
              <div class="checkbox-list">
                <template v-for="(level2, i) of companies_level_2">
                  <div :key="i" v-if="level2.show">
                    <div class="d-flex align-items-center mb-6">
                      <label class="radio radio-outline radio-success mr-5">
                        <input
                          type="radio"
                          v-model="company_level_2_select"
                          name="company_level_2_select"
                          :value="level2.company_id"
                          @change="level_was_selected(2)"
                        />
                        <span class="mr-2"></span>{{ level2.name }}
                      </label>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </b-col>
        </b-row>

        <!-- next level 3 -->
        <b-row class="mt-8 level3-selection" v-if="companies_level_3.length > 0">
          <b-col>
            <div class="boxed-select">
              <p class="boxed-header mb-4">{{ company_select_labels[3] }}</p>
              <div class="checkbox-list">
                <template v-for="(level3, i) of companies_level_3">
                  <div :key="i" v-if="level3.show">
                    <div class="d-flex align-items-center mb-6">
                      <label class="radio radio-outline radio-success mr-5">
                        <input
                          type="radio"
                          v-model="company_level_3_select"
                          name="company_level_3_select"
                          :value="level3.company_id"
                          @change="level_was_selected(3)"
                        />
                        <span class="mr-2"></span>{{ level3.name }}
                      </label>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </b-col>
        </b-row>


        <b-row>
          <b-col>
            <div class="mt-16" v-if="root_company.extra_option !== null && root_company.extra_option !== undefined">

              <div class="boxed-select">
                <p class="boxed-header mb-4">{{ company_select_labels[4] }}</p>

                <ExtraCompanyMultipleOptions
                  v-if="root_company.extra_option === 'checkbox'"
                  :companies="extra_company_options"
                  :extra_visibility_option="root_company.extra_visibility_option"
                  @changed="extra_company_ids_changed"
                />

                <ExtraCompanySingleOption
                  v-if="root_company.extra_option === 'radio'"
                  :companies="extra_company_options"
                  :extra_visibility_option="root_company.extra_visibility_option"
                  @changed="extra_company_ids_changed"
                />
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-8" v-if="names_of_companies_memberships !== ''">
          <b-col>
            <p style="color: #949494;">{{ $t('PUBLIC.REGISTRATION.YOU_WILL_BE_MEMBER') }} {{ names_of_companies_memberships }}</p>
          </b-col>
        </b-row>


      </div>

    </div>


  </div>
</template>

<style lang="css" scoped>

.boxed-header {
  font-weight: bold;
}

.boxed-select {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding-left: 12px;
  padding-top: 12px;
  margin-right: 32px;
}

.fancy-box {
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin-right: 32px;
}
</style>

<script>

import ExtraCompanyMultipleOptions from '@/view/pages/members/register/registerwizard/company_select_v2/ExtraCompanyMultipleOptions.vue';
import ExtraCompanySingleOption from '@/view/pages/members/register/registerwizard/company_select_v2/ExtraCompanySingleOption.vue';
import axios from 'axios';

export default {
  name: 'company_select_registration_v2',
  props: ['age', 'page', 'region_code', 'commune_code', 'company_id', 'page_data', 'requirements'],
  emits: ['update:page_data', 'wizardPageWasUpdated', 'selected'],
  components: {
    ExtraCompanyMultipleOptions,
    ExtraCompanySingleOption
  },

  computed: {
    is_age_valid() {
      return this.age && this.age >= 0 && this.age <= 120;
    },
    show_level_1() {
      const found = this.companies_level_1.filter(item => item.show);

      return found.length > 0;
    }
  },

  data() {
    return {
      local_page_data: {},
      names_of_companies_memberships: '',

      root_company_visual_select: null,

      error_occured: null,
      loading: true,
      root_company: {},

      company_id_lookup: {},

      company_select_labels: [' ',' ',' ',' ',' '],

      companies_level_1: [],
      companies_level_2: [],
      companies_level_3: [],
      companies_level_4: [],

      extra_company_options: [],

      company_level_1_select: null,
      company_level_2_select: null,
      company_level_3_select: null,
      company_level_4_select: null,

      main_company_selected: null,
      extra_company_ids_selected: [],

      form: {},
      formData: [],
      company_ids: [],
      registration: {
        extra_data: null
      }
    };
  },
  async mounted() {
    if (this.page_data) {
      this.local_page_data = { ...this.page_data }
    }
    if (!this.company_id) {
      return;
    }

    await this.get_public_registration_hierachy();

    this.emit_page_form();
  },
  watch: {
    age() {
      this.extra_company_options = [];
      this.recursive_find_extra_companies(this.root_company);

      this.extra_company_options.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }

        return 1;
      });

      if (this.is_age_valid) {
        this.extra_company_options = this.extra_company_options.filter(item => {
          try {
            const min_age = parseInt(item.age_req_all[0]+'');
            const max_age = parseInt(item.age_req_all[1]+'');
            
            return this.age >= min_age && this.age <= max_age;
          }
          catch (err) {
            console.error('cant parse age_req_all', item.age_req_all, 'for company', item.name, 'error', err);
          }
          
          return true;
        });
      }
    },
    
    page_data: {
      deep: true,
      handler(val) {
        this.local_page_data = { ...val };
      }
    },
    company_id(newValue) {
      if (newValue) {
        this.get_public_registration_hierachy();
      }
    },

    'page_data.data.details.commune_code': function(newVal, oldVal) {
      if (!newVal || newVal === oldVal) return;

      this.run_match_all(this.page_data.data.details.region_code, newVal, this.page_data.data.details?.dob);
    }

  },
  methods: {

    async run_match_all(region_code, commune_code, dob) {
      if (commune_code !== null && commune_code !== '') {

        console.log('will area match ' + commune_code);

        const company = this.recursive_find_first_company_with_commune_code(this.root_company, commune_code);

        if (company !== null) {
          console.log('matched company', company);

          const companies_to_select = [];
          companies_to_select.push(company);
          this.select_companies_in_hierarchy(company, companies_to_select);

          /// reverse companies_to_select array
          companies_to_select.reverse();

          console.log('selecting companies in order', companies_to_select);

          for (let i = 0; i < companies_to_select.length; ++i) {
            const company = companies_to_select[i];

            if (company.company_id === this.root_company.company_id) {
              this.root_company_visual_select = company.company_id;
            }
            else {
              this[`company_level_${i}_select`] = company.company_id;
              this.level_was_selected(i);
              console.log('selecting company', company.company_id, 'in level', (1), 'with name', company.name);
            }

            // wait for next tick
            await new Promise(resolve => setTimeout(resolve, 5));
          }

          this.$nextTick(()=>{
            this.emit_page_form();
          });

          console.log('companies_to_select', companies_to_select)
        }
      }
    },

    select_companies_in_hierarchy(node, companies) {
      if (node.pcid === node.company_id || !(node.pcid in this.company_id_lookup)) {
        return;
      }

      const parent_company = this.company_id_lookup[node.pcid];

      companies.push(parent_company);

      this.select_companies_in_hierarchy(parent_company, companies);
    },


    recursive_find_extra_companies(node) {
      if (node.extra) {
        this.extra_company_options.push(node);
      }

      if (node.children) {
        for (const child of node.children) {
          this.recursive_find_extra_companies(child);
        }
      }
    },

    recursive_find_first_company_with_commune_code(node, commune_code) {
      if (node.area_allocation && node.area_allocation.find(item => item.cc === commune_code)) {
        return node;
      }

      if (node.children) {
        for (const child of node.children) {
          const n = this.recursive_find_first_company_with_commune_code(child, commune_code);

          if (n !== null) {
            return n;
          }
        }
      }

      return null;
    },

    extra_company_ids_changed(company_ids) {
      this.extra_company_ids_selected = company_ids;
      this.emit_page_form();
    },

    /// gets the SELECTED companies
    /// if you want to get the companies that have MEMBERSHIPS, you need to filter this down
    get_selected_company_ids() {
      const company_ids = [];

      if (this.root_company_visual_select) {
        company_ids.push(this.root_company_visual_select);
      }

      for (let i = 0; i < 5; ++i) {
        const company_id = this[`company_level_${i}_select`];

        if (!company_id) {
          continue;
        }

        company_ids.push(company_id);
      }

      for (const company_id of this.extra_company_ids_selected) {
        company_ids.push(company_id);
      }

      return company_ids;
    },

    get_companies_in_company_ids(node, company_ids, result_array) {

      if (company_ids.find(item => item === node.company_id) !== undefined) {
        result_array.push(node);
      }

      for (const child of node.children) {

        this.get_companies_in_company_ids(child, company_ids, result_array);
      }
    },

    emit_page_form() {
      const actual_company_ids = this.get_selected_company_ids_with_memberships();

      this.local_page_data[this.page.id] = {
        ...this.page_data[this.page.id],
        form: { area_alloc: { company_ids: actual_company_ids } },
        validation: { $invalid: actual_company_ids.length === 0 }
      }

      this.$emit('update:page_data', this.local_page_data);

      this.$emit('selected', actual_company_ids);

      this.formWasUpdated();
    },

    get_selected_company_ids_with_memberships() {
      const company_ids = this.get_selected_company_ids();

      let companies = [];

      this.get_companies_in_company_ids(this.root_company, company_ids, companies);

      companies = companies.filter(item => item.membership);

      // concat all names together separated by comma
      this.names_of_companies_memberships = companies.map(item => (item.name)).join(', ');

      if (!this.page_data) {
        return [];
      }

      const actual_company_ids = companies.map(item => item.company_id);

      return actual_company_ids;
    },

    level_was_selected(level) {

      let target_level = null;
      let current_level = null;

      /// popuplate the next level
      switch (level) {
        case 0:
          target_level = this.companies_level_1;
          current_level = this.root_company;
          break;
        case 1:
          target_level = this.companies_level_2;
          current_level = this.companies_level_1;
          break;
        case 2:
          target_level = this.companies_level_3;
          current_level = this.companies_level_2;
          break;
        case 3:
          target_level = this.companies_level_4;
          current_level = this.companies_level_3;
          break;
      }

      /// find the selected company, actually works
      const selected_company = current_level.find((company) => {
        return company.company_id === this[`company_level_${level}_select`];
      });

      this[`company_level_${level+1}`] = null;
      this[`company_level_${level+2}`] = null;

      this[`company_level_${level+1}_select`] = null;
      this[`company_level_${level+2}_select`] = null;

      /// set next level companies
      this[`companies_level_${level+1}`] = selected_company.children.filter(item => item.show);

      // force the name string
      this.get_selected_company_ids_with_memberships();

      if (selected_company.membership) {
        this.main_company_selected = selected_company;
      }

      this.emit_page_form();

      this.$nextTick(() => {
        this.scroll_to_smooth_classname(`level${level+1}-selection`);
      });
    },

    recursive_setup_company_id_lookup(node) {
      this.company_id_lookup[node.company_id] = node;

      if (node.children) {
        for (const child of node.children) {
          this.recursive_setup_company_id_lookup(child);
        }
      }
    },

    async get_public_registration_hierachy(company_id) {
      try {
        const res = await axios.get(`/company/public-registration/hierarchy/${this.company_id}`);

        if (res.status !== 200) {
          this.error_occured = 'get_public_registration_hierachy load_hierarchy';
          this.loading = false;
          return;
        }

        this.root_company = res.data;
        this.loading = false;

        /// setup all lookups per company_id
        this.company_id_lookup = {};
        this.recursive_setup_company_id_lookup(this.root_company);


        this.company_select_labels = [' ',' ',' ',' ',' '];

        if (this.root_company.company_select_labels && this.root_company.company_select_labels.length === 5) {
          this.company_select_labels = this.root_company.company_select_labels;
        }

        this.companies_level_1 = this.root_company.children;
        this.root_company_visual_select = this.root_company.company_id;

        this.extra_company_options = [];
        this.recursive_find_extra_companies(this.root_company);

        this.extra_company_options.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }

          return 1;
        });

        if (this.is_age_valid) {
          this.extra_company_options = this.extra_company_options.filter(item => {
            if (item.age_req_all && item.age_req_all[0] && item.age_req_all[1]) {
              return this.age >= item.age_req_all[0] && this.age <= item.age_req_all[1];
            }

            return true;
          });
        }

      }
      catch (err) {
        this.error_occured = 'get_public_registration_hierachy exception';
        this.loading = false;
        console.error(err);
      }
    },

    scroll_to_smooth_classname(classname) {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

    getData() {
      return {
        company_ids: this.get_selected_company_ids()
      }
    },
    formWasUpdated() {

      const actual_company_ids = this.get_selected_company_ids_with_memberships();

      this.form.area_alloc = { company_ids: actual_company_ids };

      this.$emit('wizardPageWasUpdated', this.page.type, this.form, actual_company_ids.length > 0);

      console.log('emitting new company ids', this.form);

      if (this.page.settings && this.page.settings.skip_if_ok) {
        // console.error('---- will skip if ok ----');
      }
    },
    isNextButtonOK() {
      return this.get_selected_company_ids_with_memberships().length > 0;
    },
    isFieldValid(name) {
      console.error('isFieldValid area_alloc not implemented');
    },

    setForm(data) {
      this.formData = JSON.parse(JSON.stringify(data));
      // this.company_ids = data;
      this.formWasUpdated();
    }

  }
};
</script>
