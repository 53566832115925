<template>
  <div>

      <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
        <div class="wizard-label">
        <h3 class="wizard-title"><span>{{ step }}</span> {{ header }}</h3>
        <div class="wizard-bar"></div>
        </div>
    </div>

    </div>
    
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'register-wizard-header',
  props: ['state', 'header', 'step'],
  components: {
    
  },
  mixins: [ toasts ],
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {
    
  }
};
</script>
