<template>
  <div>
    <div class="form-group">
      <div class="check-list">
        <template v-for="(prop, j) in group.properties">
          <div class="d-flex align-items-center mb-4" v-bind:key="j">

            <PropertyPageGroupCheckboxPropertyWithText
              :group="group"
              :prop="prop"
              @change="attribute_changed"

            />

          </div>

        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.checkbox > span {
  background-color: white;
  border: 1px solid #ddd;
}
</style>
<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import PropertyPageGroupCheckboxPropertyWithText from '@/view/pages/members/register/registerwizard/PropertyPageGroupCheckboxPropertyWithText.vue';

export default {
  name: 'PropertyPageGroupCheckboxProperties',
  mixins: [ toasts ],
  components: {
    PropertyPageGroupCheckboxPropertyWithText
  },
  props: ['group'],
  emits: ['attributes_selected'],
  data() {
    return {
      selected_attributes: []
    };
  },
  mounted() {

  },

  watch: {

  },

  methods: {


    /* prop_id: number, selected: boolean, text: string | null */
    attribute_changed(prop_id, selected, text) {
      if (!selected) {
        this.selected_attributes = this.selected_attributes.filter(p => p.prop_id !== prop_id);
      } else {
        this.selected_attributes.push({ prop_id, text });
      }

      console.log('emit attributes_selected', this.group, this.selected_attributes)
      this.$emit('attributes_selected', this.group, this.selected_attributes);
    },
  }
};
</script>
